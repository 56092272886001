.ctn {
}

.top {
  display: flex;
}

.nameCtn {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  line-height: 0;
}

.name {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;

  color: #ffffff;
}

.subname {
  margin-top: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;

  color: #c4c4c4;
}

.views {
  flex: none;
  display: flex;
  flex-direction: column;
  gap: 19px;
}

.viewsTop {
  display: flex;
  align-items: center;
}
.viewsText {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  /* identical to box height */

  color: #c4c4c4;

  & + * {
    margin-left: 13px;
  }
}
.viewsButton {
  background-color: rgba(28, 27, 32, 0.7);
  border: 2px solid rgba(255, 255, 255, 0.4);
  border-radius: 12px;
}
.viewsStat {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  /* identical to box height */

  text-align: center;

  color: #c4c4c4;

  &,
  & + .viewsButton {
    margin-left: 20px;
  }
}
.viewsNums {
  justify-content: flex-end;
  display: flex;
  gap: 10px;
  align-items: center;

  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  /* identical to box height */

  color: #898989;
}

.addToList {
  height: 44px;
  position: relative;

  & > * {
    border-radius: 22px;
    width: 100%;
    border-color: rgba(255, 255, 255, 0.4);

    z-index: 1;
    position: absolute;
    background-color: #1c1b20;

    & > label {
      padding-top: 5px;
      padding-bottom: 6px;
    }
  }
}

.createRoom,
.download {
  display: flex;

  align-items: center;
  width: fit-content;

  background-color: rgba(28, 27, 32, 0.7);
  border: 2px solid rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(30px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 25px;
  padding: 6px 19px;
  gap: 10px;

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;

    color: #ffffff;
  }
}

.download {
  padding: 8px 13px;
}
.actions {
  display: flex;
  gap: 11px;
}

.grid {
  display: grid;

  grid-template-columns: auto auto 1fr;
  grid-auto-rows: auto;
  grid-auto-flow: row;

  grid-gap: 20px 0;

  grid-template-rows: repeat(4, auto) 1fr;

  .gridCover {
    grid-column: span 2;
  }

  .gridTags {
    grid-column: span 1;
  }

  .gridAddToList {
    grid-column: span 2;
  }

  .gridRating {
    grid-column: span 2;
  }

  .gridCreateRoom {
    grid-column: span 1;
  }

  .gridDownload {
    grid-column: span 1;
    padding-left: 12px;
  }

  .gridCast {
    grid-column: span 2;
  }

  .gridDescription {
    grid-row: span 4;
  }

  .gridDescription,
  .gridTags {
    padding-left: 38px;
  }
}

.description {
  padding: 15px 30px;

  background-color: rgba(28, 27, 32, 0.7);
  border: 2px solid rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(50px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 30px;
  height: 100%;
}

.descriptionHead {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  /* identical to box height */

  color: #ffffff;
}

.descriptionHr {
  margin-top: 9px;
}

.descriptionText {
  margin-top: 14px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;

  text-align: justify;

  color: #c4c4c4;
}

.hr {
  margin-top: 20px;

  & + .grid {
    margin-top: 38px;
  }
}

.related {
  margin-top: 36px;
}
