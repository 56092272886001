.badge {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0px 13px;

  background: rgba(28, 27, 32, 0.7);
  /* Пользователь */

  border: 2px solid #79afff;
  backdrop-filter: blur(30px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 17px;

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: var(--fill);
  }
}
