.ctn {
  width: 100%;
 // height: 720px;
  aspect-ratio: 16 / 9;

  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  border: 2px solid rgba(255, 255, 255, 0.4);
  border-radius: 30px;

  overflow: hidden;
  position: relative;
}

.cover {
  object-fit: cover;
  display: block;
}

.cover,
.coverFade,
.player,
.controlsCtn {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  pointer-events: none;
  & > * {
    pointer-events: initial;
  }
}

.coverFade {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
}

.player {
  position: absolute;
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px 30px;
}

.controlsGroup {
  gap: 15px;
  display: flex;
  align-items: flex-start;
}

.playPause {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

  padding: 25px;

  background-color: rgba(28, 27, 32, 0.7);
  border: 2px solid rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(30px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 75px;
}

.button {
  background-color: rgba(28, 27, 32, 0.7);
  border: 2px solid rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(50px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 20px;

  padding: 4px 11px;
}
