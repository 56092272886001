.grid {
  display: grid;

  //  width: 1284px;
  margin: 0 auto;
  gap: 40px;
  justify-content: center;

  grid-template-columns: repeat(3, auto);
}

.switchCtn {
  display: flex;
  align-items: center;
}
.switch {
  margin: 0 auto;
  margin-top: 30px;

  position: relative;
  display: flex;
  gap: 10px;
  align-items: flex-end;
  svg {
    margin-bottom: 9px;
  }

  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  /* identical to box height */
  justify-content: center;

  text-align: center;

  color: #ffffff;

  input {
    position: absolute;
    opacity: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    cursor: pointer;

    &:checked ~ {
      svg {
        transform: rotate(90deg);
      }

      .switchText {
        width: 79px;

        .switchClosed {
          opacity: 0;
        }
      }
    }
    &:not(:checked) ~ {
      .switchText {
        width: 183px;

        .switchOpen {
          opacity: 0;
        }
      }
    }
  }
}

.switchText {
  transition: width 0.5s;
  position: relative;
  height: 35px;
}

.switchClosed,
.switchOpen {
  white-space: nowrap;
  left: 0;
  top: 0;
  position: absolute;
  transition: opacity 0.5s;
}

.gridMore {
  //overflow: hidden;
  // transition: height 0.5s;
  margin-bottom: -30px;
  min-height: 30px;

  .grid {
    padding: 30px;
    padding-bottom: 30px;
  }
}
