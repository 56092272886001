.ctn {
  background-color: rgba(28, 27, 32, 0.7);
  border: 2px solid rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(50px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 30px;

  padding: 20px;
  padding-left: 0;
  padding-right: 0;

  width: 100%;

  overflow: hidden;
}

.header {
  padding-left: 20px;
  padding-right: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  /* identical to box height */
  display: block;

  color: #ffffff;

  & + .scroll {
    margin-top: 5px;
  }
}

.scrollContent {
  padding-left: 20px;
  padding-right: 20px;
  overflow-x: auto;
  overflow-x: overlay;
  display: flex;
  gap: 6px;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;

  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  & {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.scroll {
  height: 44px;
  overflow: hidden;
  position: relative;
  & + .header {
    margin-top: 36px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: -25px;

    width: 54px;
    height: 100%;
    // rgba(28, 27, 32, 0.7)

    background-image: linear-gradient(
      90deg,
      transparent 0%,
      rgba(28, 27, 32, 1) 59.35%
    );
  }
  &::before {
    z-index: 1;
    content: '';
    position: absolute;
    top: 0;
    left: -25px;
    transform: rotate(180deg);

    width: 54px;
    height: 100%;
    // rgba(28, 27, 32, 0.7)

    background-image: linear-gradient(
      90deg,
      transparent 0%,
      rgba(28, 27, 32, 1) 59.35%
    );
  }
}
