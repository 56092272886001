.ctn {
  background-color: rgba(28, 27, 32, 0.7);
  border: 2px solid rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(50px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 30px;
  padding: 30px;

  display: flex;
  align-items: center;
  gap: 18px;
}

.avatar {
  width: 74px;
  height: 74px;
  flex: none;
  align-self: flex-start;
  
}
