.ctn {
  position: relative;
}

.popup {
  box-sizing: content-box;

  background: #1c1b20;
  backdrop-filter: blur(30px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 20px;
  padding: 10px;
}

.link {
  display: block;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 2px 10px;
  gap: 5px;

  background: rgba(255, 255, 255, 0.2);
  border-radius: 12px;

  & + .link {
    margin-top: 10px;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.4);
  }

  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  /* identical to box height, or 144% */

  color: #ffffff;
}

.divider {
  height: 20px;
}
