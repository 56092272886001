.ctn {
  padding: 41px 28px;
  display: flex;
  justify-content: space-between;
}

.modal {
  background: rgba(28, 27, 32, 0.7);
  border: 2px solid rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(50px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 30px;
  z-index: 1;
}

.back {
  display: flex;
  align-items: center;
  gap: 9px;
  position: relative;
  align-self: flex-start;
}

.backArrow {
  padding: 8px;

  background: rgba(28, 27, 32, 0.7);
  border: 2px solid rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(50px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 15px;
}

.backText {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  /* identical to box height */

  color: #ffffff;
}

.backgroundImage,
.backgroundVideo {
  position: fixed;
  pointer-events: none;
  left: 0;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;

  object-fit: cover;


}
