.Header {
  position: sticky;
  width: 100%;
  top: 10px;

  // min-width: 1515px;

  display: flex;
  align-items: center;

  //padding: 0 128px 0 55px;
  z-index: 100;

  padding: 0 80px;

  & > * {
    flex: none;
  }
}

.logoSvg {
  padding: 11px 20px;

  background: #ffffff;
  backdrop-filter: blur(30px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 15px;
  display: block;
  box-sizing: content-box;

  width: 206px;
  height: 28px;

  position: relative;

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &.logoSvg_passive {
    --fill: #ffffff;
    background-color: rgba(255, 255, 255, 0.2);
  }
}

.menuButtons {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;

  background: rgba(28, 27, 32, 0.9);
  backdrop-filter: blur(30px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 15px;
  padding: 10px;
}

.menuButton {
  display: flex;
  align-items: center;

  padding: 2px 10px;
  gap: 5px;

  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(30px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 12px;

  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;

  --fill: #ffffff;
}

.logoSvg {
  & + .menuButtons {
    margin-left: 20px;
  }
}
.menuButtons {
  //margin-right: 45px;
  margin-right: 20px;
}

.searchBar {
  width: 540px;
}

.menuMore {
  margin-left: 20px;
  flex: none;
}

.group {
  margin-left: auto;
  padding: 7px;

  background: rgba(28, 27, 32, 0.9);
  backdrop-filter: blur(30px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 15px;
  gap: 23px;
  display: flex;

  & + .avatar {
    margin-left: 8px;
  }
}
