.ctn {
  border: 2px solid #878787;
  border-radius: 30px;
  width: 404px;
  height: 554px;
  overflow: hidden;
  position: relative;
}

.bg {
  position: absolute;
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.info {
  padding: 15px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(28, 27, 32, 0.7);
  border-top: 2px solid #878787;
  backdrop-filter: blur(30px);
  display: flex;
  flex-direction: column;
}
.name {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;

  color: #ffffff;
}

.link {
  display: block;
  padding: 5px 14px;
  box-sizing: content-box;

  background-color: #ffffff;
  border: 2px solid rgba(255, 255, 255, 0.4);
  border-radius: 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;

  color: #484848;

  margin-top: 15px;
  width: fit-content;
  align-self: flex-end;
}

.date {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;

  color: #878787;

  display: flex;
  justify-content: space-between;
  margin-top: 14px;
}
