.ctn {
  background-color: rgba(28, 27, 32, 0.7);
  border: 2px solid rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(10px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 30px;
  width: fit-content;
  padding-left: 10px;
  padding-right: 30px;
  width: 100%;

  .select,
  .rating {
    transition: opacity 0.2s linear;
  }

  .select {
    opacity: 0;
  }

  &:hover {
    .rating {
      opacity: 0;
    }

    .select {
      opacity: 1;
    }
  }
}

.rating {
  display: flex;
  align-items: center;
}

.text {
  font-style: normal;
  font-weight: 400;
  font-size: 38px;
  line-height: 55px;
  margin-left: 12px;

  color: #ffffff;
}

.count {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  display: block;
  position: relative;

  color: #c4c4c4;
  padding-bottom: 7px;
  align-self: flex-end;
  margin-left: auto;

  span {
    bottom: 0;
    right: 0;
    display: block;
    position: absolute;
  }
}

.star {
  cursor: pointer;
  display: block;

  input {
    position: absolute;
    opacity: 0;
  }

  svg {
    &:nth-child(2) {
      display: block;
    }

    &:last-child {
      display: none;
    }
  }

  input:checked ~ svg {
    &:nth-child(2) {
      display: none;
    }

    &:last-child {
      display: block;
    }
  }
}

.select {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -55px;
}

.stars {
  display: flex;
  gap: 9px;
  flex: none;
}

.value {
  margin-left: 23px;
  text-align: center;

  font-style: normal;
  font-weight: 400;
  font-size: 38px;
  line-height: 55px;

  color: #ffffff;
}
