.button {
  box-sizing: content-box;

  backdrop-filter: blur(30px);

  border-radius: 18px;
  padding: 1px 6px 1px 6px;
  position: relative;

  padding: 2px 10px;

  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(30px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 15px;
}

.buttonStatus {
  width: 14px;
  height: 14px;

  background-color: #ff4343;
  border-radius: 7px;
  position: absolute;
  top: -3px;
  right: 0px;
}

.menu {
  background-color: rgba(28, 27, 32, 0.7);
  height: 650px;
  width: 500px;

  display: flex;
  flex-direction: column;
  //padding-bottom: 10px;
  //backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);

  /* Note: backdrop-filter has minimal browser support */

  border-radius: 20px;
}

.menuCtn {
  //position: absolute;
  //top: calc(100% + 21px);
  //right: 0;
  z-index: 56;
}

.ctn {
  position: relative;
}

.notifications {
  flex: 1 1 auto;
  position: relative;
  padding-right: 5px;

  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
}

.notificationsContent {
  position: absolute;
  top: 10px;
  left: 0;
  height: calc(100% - 20px);
  width: calc(100% - 5px);
  overflow-y: auto;

  padding: 0px 5px;
  padding-left: 10px;

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ffffff;
    border-radius: 4px;
    background-clip: padding-box;
    border: none;
  }
  &::-webkit-scrollbar-track {
    background: #15141c;
    background: transparent;
  }

  &:empty:after {
    content: 'Тут пока ничего нет, Ня)(>ω<)';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    text-align: center;

    color: rgba(255, 255, 255, 0.6);

    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

.tabs {
  display: flex;
  white-space: nowrap;
  justify-content: space-between;
  flex: none;

  padding: 0 16px;
  padding-top: 16px;
}

.tab {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  /* identical to box height */

  text-align: center;

  color: #c4c4c4;

  padding: 3px 20px;

  // font-style: normal;
  // font-weight: 400;
  // font-size: 20px;
  // line-height: 29px;
  // /* identical to box height */

  // text-align: center;

  // color: rgba(255, 255, 255, 0.6);

  // min-width: 118px;
  // position: relative;
  // padding-bottom: 7px;

  &[checked-dom='true'] {
    color: #ffffff;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
  }
}

.notification {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;

  display: flex;
  position: relative;

  //min-height: 120px;

  display: flex;

  padding: 10px;
  gap: 10px;

  & + .notification {
    margin-top: 5px;
  }

  a {
    color: #ffa0bd;

    &:hover {
      text-decoration-line: underline;
    }
  }

  &:not([viewed='true'])::after {
    content: '';
    width: 10px;
    height: 10px;
    background: #ff4545;
    border-radius: 50%;
    position: absolute;
    left: 0px;
    top: 0px;
  }
}

.notificationVisual {
  flex: none;
  display: flex;
  align-items: center;
  position: relative;
}

.notificationName {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  /* identical to box height */

  color: #ffffff;
}
.notificationContent {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  /* identical to box height, or 111% */

  color: #ffffff;
}

.notificationTime {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  text-align: right;

  color: rgba(255, 255, 255, 0.6);
}
.notificationClose {
  align-self: flex-start;

  &:hover {
    --fill: #fff;
  }
}

.notificationBody {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}
.notificationName,
.notificationTime {
  flex: none;
}

.notificationTime {
  margin-top: 1px;
}

.notificationName {
  margin-bottom: 6px;
}

.notificationContent {
  flex: 1 1 auto;
  min-height: 40px;
}

.notificationImage {
  width: 74px;
  height: 105px;
  display: block;
  object-fit: cover;
  border-radius: 10px;
}

.notificationShowName {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  /* or 100% */

  color: #ffffff;
}

.notificationReplyContent {
  max-height: 40px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.notificationReplyReplied {
  white-space: nowrap;
  color: rgba(255, 255, 255, 0.6);
  overflow: hidden;
  width: 100%;
  position: relative;
  display: flex;
  gap: 5px;

  a {
    flex: 1 1 auto;
    overflow: hidden;
    width: 0px;
    text-overflow: ellipsis;
  }
}
.notificationReplyRepliedCmt {
  flex: none;
}

.notificationReplyRepliedText {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  text-overflow: ellipsis;
  overflow: hidden;
}
.notificationReplyReplied {
  margin-top: 4px;
}

.notificationComment {
}

.markRead {
  margin: 10px;
  display: flex;
  padding: 2px 10px;
  gap: 5px;

  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  text-align: center;

  color: #ffffff;
  width: fit-content;
}

a.achievementName {
  color: #ffe03e;

}
