.ctn {
  background-color: rgba(28, 27, 32, 0.7);
  border: 2px solid rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(50px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 20px;

  min-width: 180px;
  overflow: hidden;

  .scrollContainer {
    height: 0;
  }

  &.ctn_open {
    .scrollContainer {
      height: auto;
    }
  }
}
.top {
  padding: 2px 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */

  color: #ffffff;
}

.scroll {
  position: relative;
  padding-top: 5px;
  padding-right: 9px;

  &::before {
    position: absolute;
    height: 2px;
    content: '';
    top: 0;
    left: 15px;
    right: 15px;
    display: block;
    background-color: rgba(255, 255, 255, 0.4);
  }
}
.scrollContent {
  overflow: auto;
  max-height: 275px;
  padding: 3px;
  padding-left: 20px;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.7);
    outline: none;
    border-radius: 1.5px;
  }
}

.li {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */
  width: 100%;
  text-align: start;

  color: #797979;

  & + .li {
    margin-top: 8px;
  }

  &.li_active {
    color: #ffffff;
    position: relative;

    &::after {
      position: absolute;
      content: '';
      top: calc(50% - 3px);
      right: 9px;

      display: block;
      width: 6px;
      height: 6px;
      border-radius: 50%;

      background-color: #ffffff;
    }

    & ~ .li {
      color: #c4c4c4;
    }
  }
}
