.ctn {
  background-color: rgba(28, 27, 32, 0.7);
  border: 2px solid rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(50px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 30px;
  padding: 30px;
}

.head {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 40px;

  color: #ffffff;
  margin-bottom: 14px;
}

.comments {
  padding-top: 52px;
}

.comment {
  & + .comment {
    margin-top: 58px;
  }
}
