.ctn {
  height: 100vh;
  min-height: 724px;
  margin-top: -52px;

  &::after {
    content: '';
    display: block;
    height: calc(1682px - 100vh);
    height: calc(3237px - 100vh);
    min-height: 709px;
    width: 100%;

    background-image: linear-gradient(
      180deg,
      #15141c 25.79%,
      rgba(21, 20, 28, 0) 95.5%
    );

    height: calc(1915px);
    background-image: linear-gradient(
      180deg,
      #15141c 76.79%,
      rgba(21, 20, 28, 0) 95.5%
    );
  }
}

.chips {
  display: flex;
  flex-wrap: wrap;
  gap: 11px;

  & + .chips {
    margin-top: 16px;
  }
}

.slide {
  height: 100vh;

  min-height: 724px;
  position: relative;
  padding: 80px;

  display: flex;
  flex-direction: column;

  & > {
    position: relative;
  }
}

.slideVideoCtn {
  position: absolute;
  inset: 0;
  z-index: -1;
}

.slideVideoOverlay {
  position: absolute;
  inset: 0;
  background-image: linear-gradient(
      180deg,
      rgba(21, 20, 28, 0.5) 0%,
      rgba(21, 20, 28, 0) 50.05%
    ),
    linear-gradient(360deg, #15141c 1.34%, rgba(21, 20, 28, 0) 43.83%);
}
.slidePrevewMediaElement {
  position: absolute;
  inset: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slideTitle {
  max-width: 894px;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 46px;

  color: #ffffff;

  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  margin-bottom: 32px;
}

.goToButton {
  display: block;

  box-sizing: content-box;
  padding: 5px 14px;
  background-color: #ffffff;
  border: 2px solid rgba(255, 255, 255, 0.6);
  border-radius: 30px;
  width: fit-content;

  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;

  color: #484848;

  &:hover {
    border: 2px solid rgba(255, 255, 255, 1);

    box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.5);
  }

  &[disabled] {
    pointer-events: none;
    background-color: rgba(255, 255, 255, 0.1);
    border: 2px solid rgba(255, 255, 255, 0.4);
    color: #484848;
  }
}

.autoSwitch {
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  gap: 10px;
  padding: 0 16px;
  width: fit-content;

  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 33px;
  color: #484848;

  position: absolute;
  z-index: 1;

  top: 80px;
  right: 80px;

  .autoSwitchBg {
    background-color: #ffffff;
    position: absolute;
    inset: 0;
    border: 2px solid rgba(255, 255, 255, 0.6);
    border-radius: 30px;
    box-sizing: content-box;
  }

  input {
    position: absolute;
    opacity: 0;
    inset: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 1;

    &:checked ~ {
      .autoSwitchPause {
        display: none;
      }
    }

    &:not(:checked) ~ {
      .autoSwitchPlay {
        display: none;
      }

      .autoSwitchBg {
        background-color: transparent;
        border-color: #ffffff;
      }
      span {
        color: #ffffff;
      }
    }
  }

  span,
  svg {
    position: relative;
  }
}

.scrollBotButton {
  display: flex;

  flex-direction: column;
  gap: 12px;
  align-items: center;

  position: absolute;
  bottom: 29px;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    /* identical to box height */

    text-align: center;

    color: #ffffff;
  }
}
.ctn {
  :global {
    .swiper-pagination {
      bottom: 80px;
      right: 80px;
      left: auto;
      top: auto;
      width: auto;

      display: flex;
      gap: 10px;
      align-items: flex-end;
      z-index: 1;

      .swiper-pagination-bullet {
        width: 60px;
        height: 10px;

        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 5px;
        opacity: 1;
        margin: 0;
        position: relative;
      }

      .swiper-pagination-bullet-active {
        &::after {
          background-color: #ffffff;
          box-shadow: 0px 0px 10px 0px #ffffff;

          content: '';
          border-radius: 5px;
          position: absolute;
          display: block;
          height: 100%;
          top: 0;
          left: 0;
          bottom: 0;
          width: 100%;
        }
      }
    }
  }
}

.ctn.swiperAutoplay {
  :global {
    .swiper-pagination {
      .swiper-pagination-bullet-active {
        &::after {
          animation-duration: 5s;
          animation-name: slidein;
          animation-timing-function: linear;
          animation-fill-mode: forwards;
        }
      }
    }
  }
}

.addToList {
  margin-top: auto;

  background: rgba(28, 27, 32, 0.7);
}
