.genre {
  width: 400px;
  height: 350px;

  border-radius: 30px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    .imageOverlay {
      opacity: 0;
    }
    .holoImage {
      opacity: 1;
    }

    .background {
      top: -18px;
      bottom: -18px;
      left: -20px;
      right: -20px;
    }
  }

  &.smol {
    height: 200px;
  }
}

.name {
  position: relative;

  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 52px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;

  text-shadow: 0px 0px 7px var(--cardTextShadowColor);
}

.views {
  position: absolute;
  bottom: 30px;

  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;

  text-shadow: 0px 0px 7px var(--cardTextShadowColor);
}

.image {
  position: absolute;
  object-fit: cover;
  border-radius: 30px;
  display: block;
}

.holoImage {
  opacity: 0;
  border-radius: 30px;
  background-position: center;
  background-size: cover;
  position: absolute;

  filter: blur(15px);

  top: -18px;
  bottom: -18px;
  left: -20px;
  right: -20px;
  pointer-events: none;

  transition: opacity 0.2s;
}

.imageOverlay {
  transition: opacity 0.15s;
  border-radius: 30px;
  background-color: rgba($color: #000000, $alpha: 0.5);
}

.image,
.imageOverlay {
  position: absolute;
  left: 50%;
  top: 50%;

  width: 440px;
  height: 386px;
  transform: translateX(-50%) translateY(-50%);
}

.background {
  border-radius: 30px;
  overflow: hidden;
  transition: all 0.2s;
}

.background {
  position: absolute;

  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}
