.ctn {
  overflow: hidden;
  width: 100%;
  padding: 35px 0;

  @media only screen and (min-width: 0px) {
    --swiper-width: 1145px;
  }

  @media only screen and (min-width: 1610px) {
    --swiper-width: 1444px;
  }

  @media only screen and (min-width: 1880px) {
    --swiper-width: 1724px;
  }

  .swiperCtn {
    width: var(--swiper-width);
    margin: 0 auto;

    // 1444px
  }

  position: relative;

  :global {
    .swiper {
      overflow: visible;
    }

    .swiper-pagination {
      bottom: -35px;
      left: 50%;
      top: auto;
      width: auto;
      transform: translateX(-50%);

      display: flex;
      gap: 10px;
      align-items: flex-end;
      justify-content: center;
      z-index: 1;

      .swiper-pagination-bullet {
        width: 30px;
        height: 10px;

        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 5px;
        opacity: 1;
        margin: 0;
        position: relative;

        transition: width 0.2s;
      }

      .swiper-pagination-bullet-active {
        background-color: #ffffff;
        width: 100px;
      }
    }
  }
}

.swiperCtn {
  position: relative;
  &::before,
  &::after {
    content: '';
    width: 50vw;
    top: -100px;
    bottom: -100px;
    background: linear-gradient(-90deg, transparent, #15141c 50px);
    position: absolute;

    z-index: 2;
    pointer-events: none;
  }
  &::before {
    right: 100%;
    background: linear-gradient(-90deg, transparent, #15141c 50px);
  }
  &::after {
    left: 100%;
    background: linear-gradient(90deg, transparent, #15141c 50px);
  }
}
