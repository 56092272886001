.ctn {
  border: 2px solid rgba(255, 255, 255, 0.4);
  border-radius: 30px;
  width: 100%;
  height: 100%;
  display: block;
  overflow: hidden;
  position: relative;

  &:not(:hover) {
    .icon {
      opacity: 0;
    }
  }
}

.image {
  position: absolute;
  object-fit: cover;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.icon {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;

  background-color: rgba(28, 27, 32, 0.7);
  border: 2px solid rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(10px);

  padding: 14px;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 50%;
  overflow: hidden;
}

.modalCtn {
  align-items: center;
  justify-content: center;
  z-index: 100;
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  width: 100%;

  display: flex;

  padding: 40px;

  &:not(.modalCtn_open) {
    display: none;
  }
}

.modalClose {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba($color: #000000, $alpha: 0.5);
}

.modalImageCtn img {
  position: relative;
  width: fit-content;

  object-fit: contain;

  max-height: calc(100vh - 80px);
  max-width: 100%;
  overflow: hidden;
  border: 2px solid rgba(255, 255, 255, 0.4);
  border-radius: 30px;
}
