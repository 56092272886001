.ctn {
  position: relative;
  display: block;
}

.menu {
  background: #1c1b20;
  backdrop-filter: blur(30px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 20px;
}

.name {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  /* Designer */

  background: linear-gradient(90deg, #ff5592 0%, #a661ff 49.98%, #92fff8 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.top {
  padding: 8px;
  display: flex;
  gap: 8px;
  align-items: center;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 20px;
}

.avatar {
  flex: none;
  width: 54px;
  height: 54px;
}

.profileLink {
  margin-top: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  color: #ffa0bd;
}

.li {
  padding: 5px 6px;
  border-radius: 10px;
  cursor: pointer;

  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  display: flex;
  gap: 12px;
  align-items: center;

  color: #c4c4c4;

  &:hover {
    --fill: #ffffff;
    background: rgba(255, 255, 255, 0.1);
    color: #ffffff;
  }

  &.exit {
    color: #ff4343;
  }
}
.li,
hr {
  + {
    .li,
    hr {
      margin-top: 7px;
    }
  }
}

hr {
  margin-left: 24px;
  margin-right: 24px;
}
.icon {
}

.ul {
  margin-top: 12px;
}

.checkbox {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;

  &:not(:checked) ~ .menu {
    display: none;
  }
}

.guest {
  margin-left: 39px;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  /* identical to box height */

  color: #c4c4c4;

  padding: 8px 16px;
  gap: 10px;

  background: #ffffff;
  border-radius: 15px;

  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  color: #484848;

  display: block;
}

.floatingMenu {
  z-index: 55;
}

.linkHover,
.button {
  --fill: #c4c4c4;
  &:hover {
    --fill: white;

    background: rgba(255, 255, 255, 0.1);
  }
}

.buttons {
  gap: 10px;
  display: flex;
}
.button {
  padding: 5px 10px;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  color: #c4c4c4;

  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;

  &:hover {
    --fill: #ffffff;
    color: #ffffff;
    background: rgba(255, 255, 255, 0.1);
  }
}

.theme {
  user-select: none;
  margin-top: 15px;
  display: flex;

  justify-content: space-between;

  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  align-items: center;
  padding-left: 10px;
  cursor: pointer;

  & > span {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height, or 120% */

    color: #c4c4c4;
  }

  svg {
    // --fill: #fff;
    position: relative;
  }

  input {
    opacity: 0;
    position: absolute;
  }

  svg,
  svg * {
    transition: all 0.2s;
  }

  .themeButtonMoon {
    svg:first-child {
      opacity: 1;
    }
    svg:last-child {
      opacity: 0;
    }
  }
  .themeButtonSun {
    svg:first-child {
      opacity: 0;
    }
    svg:last-child {
      opacity: 1;
    }
  }

  input:checked {
    & ~ * {
      .themeButtonMoon {
        svg:first-child {
          opacity: 0;
        }
        svg:last-child {
          opacity: 1;
        }
      }
      .themeButtonSun {
        svg:first-child {
          opacity: 1;
        }
        svg:last-child {
          opacity: 0;
        }
      }

      .themeSwitcherBack {
        left: 50%;
      }
    }
  }
}

.themeSwitcher {
  display: flex;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;

  position: relative;
}

.themeSwitcherBack {
  background: #ffffff;
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  border-radius: 15px;
  left: 0%;

  transition: left 0.2s;
}

.themeButton {
  padding: 4px 8px;
  position: relative;
  cursor: pointer;
  width: 32px;
  height: 32px;

  box-sizing: content-box;

  svg {
    position: absolute;
  }
  svg:first-child {
    --fill: #fff;
  }
  svg:last-child {
    --fill: #484848;
  }
}

.exit {
  margin-top: 15px;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;

  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  color: #ff4343;

  padding: 5px 10px;
  gap: 5px;

  background: rgba(255, 67, 67, 0.1);
  border-radius: 15px;
}
.bot {
  padding: 10px;
}
