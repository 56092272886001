.ctn {
  display: flex;
  flex: 1 1 auto;
  position: relative;
  gap: 13px;
  padding-bottom: 5px;

  &::after {
    content: '';
    position: absolute;
    height: 2px;
    display: block;
    bottom: 0;
    //top: calc(100% + 4px);
    border-radius: 1px;
    left: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.6);
  }
  align-items: flex-end;
}

.field {
  flex: 1 1 auto;
  display: block;
  position: relative;

  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  /* identical to box height */
  width: 100%;

  color: #ffffff;
  overflow-wrap: anywhere;

  * {
    display: block;
    white-space: pre-wrap;
  }
}

.input {
  width: 100%;
  display: block;
  position: absolute;
  border: none;
  background: transparent;
  inset: 0;
  overflow: hidden;
  left: 0;
  top: 0;
  height: 100%;

  resize: none;

  &::placeholder {
    color: #9d9d9d;
  }
}

.send {
  padding: 2px 15px;
  background-color: #ffffff;
  border: 2px solid rgba(255, 255, 255, 0.6);
  border-radius: 20px;

  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  /* identical to box height */

  color: #484848;

  align-self: flex-end;
  flex: none;
  gap: 5px;

  display: flex;
  align-items: center;
  margin-bottom: 3px;
}
