.grid {
  display: grid;

  //  width: 1284px;
  margin: 0 auto;
  gap: 36px;
  justify-content: center;

  grid-template-columns: repeat(3, auto);
}
