.chip {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;

  padding: 0 10px;

  display: inline-block;

  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
  /* Note: backdrop-filter has minimal browser support */
}
