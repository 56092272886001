@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;1,100&display=swap');

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: rgba(137, 137, 137, 1);

  overflow: overlay;
}

* {
  font-family: 'Jost', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // line-height: 120%;
  background-clip: padding-box;
}

img {
  display: block;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

svg {
  display: block;
}
button {
  border-radius: 0;
  background-color: transparent;
  border: none;
  display: block;
  cursor: pointer;
}

input {
  background-color: transparent;
  border-radius: 0;
  border: 0;
  display: block;
  min-width: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

input,
button,
a {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  text-decoration: none;
}

textarea:focus,
input:focus {
  outline: none;
}

a {
  text-align: start;
}

@keyframes slidein {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}

input {
  cursor: inherit;
}

textarea {
  color: inherit;
  line-height: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  background-color: inherit;
  overflow-wrap: inherit;
}

hr {
  border: none;
  height: 2px;
  border-radius: 1px;
  background-color: rgba(255, 255, 255, 0.4);
}

* {
  //backdrop-filter: none !important;
}

html {
  @media only screen and (max-width: 1520px) {
    //zoom: 0.8;
  }
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: rgba(255, 255, 255, 0.5) #15141c;
  // scrollbar-color: transparent #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: #15141c;
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  background-clip: padding-box;
  border: 2px solid transparent;
}
