.status {
  background: rgba(173, 240, 255, 0.4);

  &[value='Анонс'] {
    background: linear-gradient(
      90deg,
      rgba(127, 127, 213, 0.8) 0%,
      rgba(134, 168, 231, 0.8) 50.52%,
      rgba(145, 234, 228, 0.8) 100%
    );
  }
}

.year {
  background: rgba(180, 173, 255, 0.4);
}

.season {
  background: rgba(82, 141, 255, 0.4);
}

.type {
  background: rgba(255, 131, 131, 0.4);
}

.age {
  background: rgba(0, 0, 0, 0.4);

  &[value='18+'] {
    background: rgba(217, 34, 34, 0.6);
  }

  &[value='21+'] {
    background: url('../assets/pictures/21.png');
  }
}
