.ctn {
  position: relative;
}

.replies {
  padding-left: 80px;
}

.reply {
  & + .reply {
    margin-top: 20px;
  }
}
.repliesLine {
  top: 90px;
  left: 34px;
  bottom: 10px;
  position: absolute;
  width: 4px;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.4);
}

.repliesNoLast {
  position: relative;

  &::before {
    content: '';
    top: -30px;
    left: -46px;
    bottom: -84px;
    position: absolute;
    width: 4px;
    border-radius: 0px 0px 2px 2px;
    background-color: rgba(255, 255, 255, 0.4);
  }
}

.comment {
  & + .replies {
    margin-top: 20px;
  }
  position: relative;

  &.commentCount {
    &::before {
      content: '';
      top: 90px;
      left: 34px;
      bottom: 10px;
      position: absolute;
      width: 4px;
      border-radius: 2px 2px 0px 0px;
      background-color: rgba(255, 255, 255, 0.4);
    }
  }
}

.repliesShowAll {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  /* identical to box height */

  color: #ffa0bd;
  padding-left: 77px;
  margin-top: 15px;
}
