.ctn {
  width: 614px;
  display: flex;
  flex-direction: column;

  padding-top: 0px;
  padding-bottom: 30px;
  height: 100%;
}

.cringe {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 40px;
  text-align: center;

  color: #ffffff;

  align-self: center;
  width: 500px;

  img {
    display: inline-block;
    margin: 0 5px -11px;
  }
}
.titleSignUp {
  margin-top: 44px;
}

.titleSignIn {
  margin-top: 64px;
}

.signInInputRowName {
  margin-top: 5px;
}
.signInInputRowPassword {
  margin-top: 25px;
}
.signInTopError {
  height: 40px;
}
.error {
  white-space: break-spaces;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  /* or 111% */

  color: #ff4343;

  margin: 0 98px;
  margin-top: 20px;

  &:not(.errorShow) {
    opacity: 0;
    user-select: none;
  }
}

.fieldError,
.warning {
  margin: 0 98px;
  margin-right: 60px;

  margin-left: 100px;
}

.fieldError {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  /* identical to box height */

  height: 26px;

  color: #ff4343;
}

.warning {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  /* identical to box height */

  color: #ffca43;
}

.field {
  display: flex;
  gap: 4px;
  margin-left: 70px;

  align-items: center;

  &.fieldIsError {
    --fill: #ff4343;

    .fieldInputContainer input {
      &:focus {
        border-bottom: 2px solid #ff4343;
      }

      background: rgba(255, 67, 67, 0.2);
      color: #ff4343;
    }
  }
}

.fieldErrorMessage {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  /* identical to box height */

  color: #ff4343;
  margin-left: 116px;
}

.fieldInputContainer {
  position: relative;
  display: flex;
  gap: 10px;
  align-items: center;

  width: 410px;

  input {
    background: rgba(255, 255, 255, 0.1);
    border-bottom: 2px solid transparent;
    border-radius: 15px;
    border-top: 2px solid transparent;
    padding: 0 20px;

    &:focus {
      border-bottom: 2px solid #ffffff;
      background: rgba(255, 255, 255, 0.2);
    }
  }

  svg {
    // margin-bottom: 2px;
  }

  input {
    flex: 1 1 auto;
  }

  // &::after {
  //   bottom: -1px;
  //   left: -1px;
  //   right: -1px;
  //   content: '';
  //   display: block;
  //   position: absolute;
  //   height: 2px;
  //   background: rgba(255, 255, 255, 0.4);
  //   border-radius: 1px;
  // }

  &,
  input::placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 36px;
    /* identical to box height */
  }

  color: rgba(255, 255, 255, 1);

  input::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }
}

.controls {
  margin: 17px 98px 0;
  display: flex;
  justify-content: space-between;

  & + .button {
    margin-top: 48px;
  }
}

.checkbox {
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  /* identical to box height */
  display: flex;

  user-select: none;

  color: #ffffff;
  gap: 10px;
  align-items: center;
  width: fit-content;

  input {
    position: absolute;
    opacity: 0;
  }

  input:not(:checked) + .checkboxIcon {
    border-color: #898989;
    svg {
      opacity: 0;
    }
  }

  input:not(:checked) ~ * {
    color: #898989;
  }
}

.checkboxIcon {
  width: 20px;
  height: 20px;

  border: 2px solid #ffffff;
  border-radius: 6px;
  position: relative;

  svg {
    position: absolute;
    top: -5px;
    left: -2px;
  }
}

.passwordLink {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  /* identical to box height */

  text-align: right;

  color: #ffa0bd;

  &:hover .passwordLinkUnderlined {
    text-decoration-line: underline;
  }
}

.button {
  background-color: #ffffff;
  border: 2px solid rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(20px);
  /* Note: backdrop-filter has minimal browser support */

  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 38px;
  /* identical to box height */

  color: #484848;

  border-radius: 30px;
  padding: 2px 60px;

  align-self: center;

  box-shadow: none;
  background-image: none;

  &:disabled {
    cursor: auto;

    background-color: rgba(255, 255, 255, 0.1);
  }

  & + .or {
    margin-top: 18px;
  }

  &:enabled:hover {
    border-color: transparent;
    background-clip: border-box;
    box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.5);
  }
}

.or {
  align-self: center;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  /* identical to box height */

  color: rgba(255, 255, 255, 0.8);

  & + .thirdButtons {
    margin-top: 18px;
  }
}

.buttonThird {
  padding: 9px 40px;

  background-color: #1c1b20;
  border: 2px solid rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(50px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 30px;

  display: flex;
  align-items: center;
  gap: 15px;

  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  /* identical to box height */

  align-self: center;

  color: #ffffff;

  span {
    //width: 197px;
  }

  & + .buttonThird {
    // margin-top: 16px;
  }

  & + .nav {
    // margin-top: 58px;
  }
}

.buttonThirdGoogle {
  padding-left: 93px;
  grid-column: span 2;
}

.nav {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  /* identical to box height */
  align-self: center;

  color: rgba(255, 255, 255, 0.6);

  a {
    color: #ffa0bd;

    &:hover {
      text-decoration-line: underline;
    }
  }
}

.doc {
  padding-top: 70px;
  margin-top: auto;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  align-self: center;
  /* identical to box height */

  text-align: center;

  color: #ffa0bd;

  &:hover {
    text-decoration-line: underline;
  }
}

.dateField {
  margin-left: 70px;
  margin-right: 98px;

  display: flex;
  align-items: center;
  gap: 4px;

  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  /* identical to box height */

  color: rgba(255, 255, 255, 0.4);
}

.dateFieldInputs {
  padding-top: 5px;
  display: flex;

  input {
    width: 100%;
    text-align: center;
  }

  .field {
    margin: 0;
    &.dateFieldDay {
      margin-left: 100px;
    }

    .fieldInputContainer {
      input {
        padding: 0 15px;
      }
      width: auto;
    }
  }
}

.dateFieldMonthCtn {
  position: relative;
}
.checkboxText {
  user-select: none;
}

.dateFieldDay {
  width: 55px;

  & + .dateFieldMonth {
    margin-left: 15px;
  }
}

.dateFieldMonth {
  position: relative;
  width: 109px;

  & + .dateFieldYear {
    margin-left: 15px;
  }
}

.dateFieldYear {
  width: 77px;
}

.dateFieldMonthDrop {
  z-index: 1;
  position: absolute;
  top: calc(100% + 10px);

  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  /* identical to box height */

  color: #c4c4c4;

  display: grid;
  grid-template-columns: repeat(3, 1fr);

  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(30px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 20px;
  padding: 10px;
  gap: 10px;

  //  padding: 5px 0;
}

.monthDropButton {
  text-align: center;
  width: 100%;
  line-height: 130%;
  padding: 0px 8px;

  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  /* identical to box height, or 145% */

  color: #c4c4c4;

  border-radius: 10px;
  &:hover {
    color: #ffffff;
    background: rgba(255, 255, 255, 0.2);
  }
}

.cringe + .inputRowEmail {
  margin-top: 45px;
}

.signUpInputRowEmail {
  margin-top: 45px;
}

.signUpInputRowName,
.signUpInputRowDate,
.signUpInputRowPasswordConfirm {
  margin-top: 10px;
}

.signUpInputRowPassword {
  margin-top: 20px;
}

.signUpButton {
  margin-top: 30px;
}

.topNavBtn {
  display: flex;
  padding: 3px 10px;
  align-items: center;
  gap: 5px;

  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(50px);

  border-radius: 12px;

  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  /* identical to box height */

  color: #ffffff;
  width: fit-content;
  margin: 15px;
  margin-bottom: 0;
}

.fieldControlIcon {
  --fill: #ffffff;
  padding: 5px;

  background: rgba(255, 255, 255, 0.2);
  border-radius: 10px;

  &[dom-show='true'] {
    --fill: #484848;
    background: rgba(255, 255, 255, 1);
  }
}
.thirdButtons {
  display: grid;
  gap: 11px 6px;
  padding: 0 98px;

  grid-template-columns: 1fr 1fr;
}

.captha {
  width: 340px;
  height: 80px;
  left: 1415px;
  top: 435px;
  margin: 0 auto;

  margin-top: 40px;

  background: rgba(28, 27, 32, 0.7);
  border: 2px solid rgba(255, 255, 255, 0.4);
  border-radius: 15px;

  & + .button {
    margin-top: 28px;
  }
}

.codeInput {
  display: flex !important;
  gap: 10px;

  input {
    width: 60px;
    height: 40px;

    background: rgba(255, 255, 255, 0.1);

    border-radius: 20px;

    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
    /* identical to box height */

    text-align: center;

    color: #ffffff;

    &::placeholder {
      color: #aaaaaa;
    }

    &:placeholder-shown {
      color: #aaaaaa;
      border-radius: 15px;
    }
  }
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.titleSignInCode {
  margin-top: 64px;
}
.signInCodeInputRowCode {
  margin-top: 65px;
}

.message {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  /* identical to box height */

  margin-left: 100px;
  color: #898989;
}

.messageTop {
  margin-top: 49px;
  height: 26px;
}

.messageTimer {
  margin-top: 8px;
  line-height: 22px;
  height: 22px;
}

.messageYellow {
  color: #ffca43;
}

.messageWhite {
  color: #ffffff;
}
.messageRed {
  color: #ff4343;
}

.messageLightGray {
  color: #c4c4c4;
}

.passwordResetInputRowNameEmail {
  margin-top: 5px;
  margin-left: 42px;

  svg {
    width: 53px;
  }
}

.captha.capthaPasswordReset {
  margin-top: 54px;
}

.capthaPasswordReset + .button.buttonSendPasswordReset {
  margin-top: 32px;
}

.titlePasswordNewSet {
  margin-top: 51px;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 40px;
  text-align: center;

  color: #ffffff;
}

.subtitlePasswordNewSet {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  text-align: center;

  color: #898989;
}
.buttonPasswordNewSet {
  margin-top: 50px;
}

.passwordNewSetRowPassword {
  margin-top: 51px;
}
