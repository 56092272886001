.ctn:not(.compact) {
  width: fit-content;

  border: 2px solid #ffffff;
  box-sizing: content-box;
  border-radius: 19px;
  margin-bottom: 16px;

  &.open {
    border: 2px solid #ffffff;
    border-radius: 15px;
  }

  & > label {
    // padding-top: 5px;
    // padding-bottom: 6px;
    input ~ div {
      flex-direction: row-reverse;
    }
  }
}

.addToListButton {
  cursor: pointer;
  display: flex;
  gap: 19px;
  padding: 0 14px 1px;
  margin-top: auto;
  width: fit-content;
  justify-content: space-between;
  width: 100%;
  min-width: 234px;

  svg {
    transition: transform 0.2s;
  }

  input {
    position: absolute;
    opacity: 0;

    &:checked {
      & ~ svg {
        transform: rotate(90deg);
      }
    }
  }

  .selected {
    display: flex;
    gap: 5px;
    align-items: center;

    span {
      color: var(--color);
    }
  }

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;

    color: #ffffff;
  }
}

.list {
  padding: 5px;
  padding-top: 10px;
}

.popup {
  height: 0px;
  overflow: hidden;

  transition: height 0.2s;

  &.popup_open {
    height: auto;
    height: 211px;
  }
}
.clearbtn {
  .li {
    color: #ff5757;
  }

  margin-top: 12px;
  & > * {
    pointer-events: none;
  }
}

.li {
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;

  position: relative;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  /* identical to box height */

  color: #ffffff;
  padding-left: 12px;

  span {
    display: block;
    position: relative;
  }

  input {
    opacity: 0;
    position: absolute;

    &:checked {
      & ~ span::before {
        content: '';
        position: absolute;
        left: -36px;
        top: 0;
        height: 100%;
        width: 2px;
        background-color: #fff;
        border-radius: 1px;
      }
    }
  }

  & + .li {
    margin-top: 7px;
  }
}

.compact {
  .li span {
    display: none;
  }

  .addToListButton {
    display: none;
  }

  .popup {
    height: auto;
  }

  .list,
  .li {
    padding: 4px;
    margin: 0;
  }

  .li + .li {
    margin-top: 2px;
  }

  .li[checked-dom='true'],
  .li:hover,
  .clearbtn:hover {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
  }

  .clearbtn {
    margin-top: 14px;
  }
}
