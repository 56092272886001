.container {
  position: relative;
}

.SearchBar {
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  position: relative;

  height: 34px;
  box-sizing: content-box;
  border-radius: 18px;
  backdrop-filter: blur(30px);
  overflow: hidden;

  height: 36px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(30px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 15px;
}

.input {
  flex: 1 1 auto;
  padding: 0 20px;

  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  /* identical to box height */

  color: #ffffff;
}

.button {
  padding: 6px 12px;
  height: 100%;

  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(30px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 15px;

  svg {
    position: relative;
  }

  &[data-disabled='true'] {
    pointer-events: none;
  }
}

.results {
  z-index: 56;
}
