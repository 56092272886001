.page {
  padding-top: 40px;
  padding-bottom: 200px;
  width: 1280px;
  margin: 0 auto;
}

.info {
  margin-top: 18px;

  & + .comments {
    margin-top: 76px;
  }
}
