.footer {
  background-color: rgba(28, 27, 32, 0.7);
  border: 2px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(50px);
  border-bottom: none;

  border-radius: 30px 30px 0px 0px;
  padding: 30px 20px 0;
}

.blocks {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  gap: 30px;

  padding-bottom: 20px;
}

.block {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
}

.blockContacts,
.blockSupport {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  /* identical to box height */

  color: #a9a9a9;

  a {
    text-decoration-line: underline;
    color: #ffffff;
  }
}

.contacts {
  gap: 30px;
  display: flex;
  flex-direction: column;
}

.supports {
  gap: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.contact {
  display: flex;
  gap: 10px;
}

.divider {
  height: 2px;
  border-radius: 1px;
  background-color: rgba(255, 255, 255, 0.2);
  width: 100%;
}

.warnin {
  text-align: center;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 29px;
  /* identical to box height */

  text-align: center;

  color: #a9a9a9;

  padding: 10px;
}
.sites {
  display: grid;
  gap: 60px 70px;
  grid-template-columns: repeat(3, auto);
}

.site {
  display: block;
}
