.ctn {
  background-color: rgba(28, 27, 32, 0.7);
  border: 2px solid rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(50px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 30px;
  overflow: hidden;
}

.head {
  width: 100%;
  padding: 25px 34px 24px;
  gap: 26px;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  /* identical to box height, or 146% */

  text-align: justify;

  color: #ffffff;

  svg {
    transition: transform 0.5s;
  }

  &[open] {
    svg {
      transform: rotate(90deg);
    }
  }
}

.related {
  overflow: hidden;
  // height: 0;
}

.relatedContent {
  padding: 0 10px 10px;
}

.relate {
  & + .relate {
    margin-top: 10px;
  }
}
