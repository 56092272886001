.ctn {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(50px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 25px;
  padding: 10px;
  display: flex;
  gap: 15px;
}

.row1 {
  display: flex;
  gap: 13px;

  .li {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}

.poster,
.showLink {
  display: block;
  width: 110px;
  height: 160px;
  object-fit: cover;
  border-radius: 15px;
  flex: none;
}

.showLink:hover ~ * .nameSpan,
.name:hover .nameSpan {
  text-decoration: underline;
}

.info {
  flex: 1 1 auto;

  display: flex;
  flex-direction: column;
  gap: 15px;
}

.name {
  position: relative;
  height: 29px;
  width: 100%;
  margin-bottom: -1px;

  .nameSpan {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
    /* identical to box height */

    letter-spacing: -0.05em;

    color: #ffffff;
    white-space: nowrap;

    text-overflow: ellipsis;
    overflow: hidden;

    display: block;
    position: absolute;
    width: 100%;
  }
}

.liName {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  /* identical to box height */

  letter-spacing: -0.05em;

  color: #c4c4c4;
}

.liLink {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  /* identical to box height */

  letter-spacing: -0.05em;

  color: #ffffff;

  &:hover {
    text-decoration-line: underline;
  }
}

.li {
  display: flex;
}

.liName + .liLink {
  margin-left: 7px;
}

* + .episode {
  margin-left: 15px;
}

.liText {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  /* identical to box height */
}

.episode {
  display: flex;
  gap: 5px;
  align-items: center;
  .episodeCount {
    letter-spacing: -0.05em;
    color: #c4c4c4;
  }
}
.liName + .chips {
  margin-left: 5px;
}

.chips {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;

  overflow: hidden;
  height: 29px;
}
