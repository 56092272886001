.ctn {
  background-color: rgba(28, 27, 32, 0.7);
  border: 2px solid rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(50px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 30px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

.li {
  display: flex;
  gap: 10px;

  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  /* identical to box height */

  color: #c4c4c4;

  & > a {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;
    color: #ffffff;

    &:hover {
      text-decoration-line: underline;
    }
  }
}

.row1 {
  display: flex;
  gap: 28px;
}

.ul {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.chips {
  display: flex;
  gap: 6px;
}

.episode {
  display: flex;
}

span.episodeCount {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  color: #ffffff;
}

span.episodeDuration {
  margin-left: 5px;
  margin-right: 18px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  /* identical to box height */

  color: #898989;
}

.ctn .chip {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  /* identical to box height */

  color: #ffffff;

  border-radius: 16px;
}

.text {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  /* identical to box height */

  color: #ffffff;
}

.ul + .subtitle {
  margin-top: 38px;
}

.subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  /* identical to box height */

  color: #ffffff;

  & + * {
    margin-top: 15px;
  }
}

.lastBlock {
  display: flex;
  flex-direction: column;
  gap: 18px;
  align-items: flex-end;
}

.shikimoriButton {
  background-color: #ffffff;
  border: 2px solid rgba(255, 255, 255, 0.4);
  border-radius: 15px;

  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;

  color: #484848;

  display: flex;
  align-items: center;

  gap: 10px;
  padding: 0 15px;
}
