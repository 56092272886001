.ctn {
  width: 274px;
  height: 384px;
  position: relative;
  display: block;

  &.smol {
    width: 235px;
    height: 325px;
  }

  &[hover='false'] {
    .innerCard {
      opacity: 0;
    }
    .addToListCtn {
      pointer-events: none;
      opacity: 0;
    }
  }
}

.card {
  border: 2px solid #b9a8ff;
  border-radius: 30px;
  position: absolute;
  display: block;

  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;

  transition: all 0.2s;

  border: 2px solid #ffeca8;
  overflow: hidden;
}

.ctn[hover='true'] .card {
  z-index: 55;
  // 290 / 270
  //transform: scale(1.06);

  top: -14px;
  bottom: -14px;
  left: -10px;
  right: -10px;

  border: 2px solid #ffeca8;
  filter: drop-shadow(0px 0px 10px rgba(255, 236, 168, 0.5));
}

.bg {
  position: absolute;
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.rating {
  position: relative;
  display: flex;
  gap: 10px;
  align-items: center;

  color: #ffffff;
  width: fit-content;
  /* Note: backdrop-filter has minimal browser support */

  padding: 3px 10px;
  gap: 10px;

  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 10px;

  margin: 10px;

  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  /* identical to box height */

  letter-spacing: -0.05em;

  color: #ffffff;
}

.age {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  /* identical to box height */

  text-align: right;

  color: #ffffff;
  padding: 1px 15px 0 22px;

  background-color: rgba(28, 27, 32, 0.7);
  backdrop-filter: blur(30px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 0px 25px 25px 0px;
  width: fit-content;
  margin-top: 30px;
}

.innerCard {
  position: absolute;
  width: 290px;
  height: 408px;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  transition: opacity 0.1s;

  background: rgba(28, 27, 32, 0.7);
  backdrop-filter: blur(50px);

  display: flex;
  flex-direction: column;
}

.innerCardBlock {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;

  & + .innerCardBlock {
    margin-top: 10px;
  }
}

.infoTitle {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;

  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  /* identical to box height */

  color: #ffffff;
}

.infoTitleAlt {
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  /* identical to box height */

  color: #c4c4c4;
}

.infoTitleAlt,
.infoTitle {
  overflow: hidden;
}

.innerCardBlocktitles {
  padding: 10px 15px;
}

.liName {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  /* identical to box height */

  color: #ffffff;
  margin-right: 10px;
}

.innerCardBlockList {
  padding: 5px 15px 10px;
}

.liText,
.liLink {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  /* identical to box height */

  color: #ffffff;
}

.liLink {
  &:hover {
    text-decoration-line: underline;
  }
}

.li {
  display: flex;
  flex-wrap: wrap;
  & + .li {
    margin-top: 10px;
  }
}

.genreChip {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;

  color: #ffffff;
  padding: 3px 10px;
}

.innerCardBlockGenres {
  padding: 5px 15px;
}
.genres {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  max-height: 57px;
  overflow: hidden;
}

.chips {
  margin-top: 22px;
  gap: 5px;
  display: flex;
}

.dotsChip,
.chipStatus,
.showChip {
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  /* identical to box height */

  text-align: center;
}

.chipStatus {
  margin-left: 10px;
}

.voices {
  position: relative;
  line-height: 100%;
  margin-top: 10px;
}

.dynamic {
  position: relative;
}

.voice {
  margin-right: 5px;
}

.itemsRender,
.itemsPreRender {
  overflow: hidden;
}
.voicesItems {
  max-height: 52px;
}

.genresItemsPopup,
.genresItems {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.genresItems {
  max-height: 57px;
}

.itemsPreRender {
  opacity: 0;
}

.itemsRender {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.voicesMoreArrow {
  margin-left: auto;

  clip-path: ellipse(50% 50% at 50% 50%);
}

.voicesMoreContent {
}

.genresMoreContent {
  justify-content: flex-end;
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

.moreContent {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  backdrop-filter: blur(20px);
  padding: 5px;
}

.voicesMore {
  width: 240px;
  z-index: 100;
  padding-bottom: 3px;
}

.addToListCtn {
  position: absolute;
  left: calc(100% + 13px);
  z-index: 100;
  transition: opacity 0.2s;

  bottom: -14px;

  background-color: rgba(28, 27, 32, 0.7);
  border: 2px solid #ffeca8;
  backdrop-filter: blur(20px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 15px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    height: 100%;
    top: 0;
    right: 100%;
    width: 10px;
  }
}

.goto {
  background-color: #ffffff;
  border: 2px solid rgba(255, 255, 255, 0.6);
  border-radius: 30px;
  display: flex;
  padding: 3px;
  width: 100%;

  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  /* identical to box height */

  color: #484848;
  gap: 5px;
  justify-content: center;
  align-items: center;
}
.gotoCtn {
  padding: 8px;
  margin-top: auto;
}
