.ctn {
  width: 540px;
  height: 530px;

  background: #1c1b20;
  backdrop-filter: blur(30px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 15px;

  padding: 10px;
  padding-right: 5px;

  &:empty {
    display: none;
  }
}

.card {
  & + .card {
    margin-top: 10px;
  }
}

.content {
  padding-right: 5px;
  max-height: 100%;
  overflow-y: auto;

  /* ===== Scrollbar CSS ===== */
  /* Firefox */
  & {
    scrollbar-width: auto;
    scrollbar-color: rgba(255, 255, 255, 0.5) #1c1b20;
    // scrollbar-color: transparent #ffffff;
  }

  /* Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #1c1b20;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border: none;
    background-color: #ffffff;
    background-color: #aaaaaa;
    border-radius: 10px;
    background-clip: padding-box;
    // border: 2px solid transparent;
  }
}
