.ctn {
  display: flex;
  gap: 8px;

  & + * .ctn {
    gap: 13px !important;

    .avatar {
      width: 64px;
      height: 64px;
      margin-top: 2px;
    }

    .head {
      padding-top: 1px;
    }
  }
}

.avatar {
  flex: none;
  width: 74px;
  height: 74px;
}

.body {
  flex: 1 1 auto;
}

.head {
  padding-top: 2px;
  display: flex;
  align-items: center;

  & + .mid {
    margin-top: 10px;
  }
}
.authorName {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  /* identical to box height */

  /* Пользователь */

  color: #79afff;
}

.authorBadge {
  margin-left: 15px;
}

.authorBadge + .reply {
  margin-left: 15px;
}

.reply + .date {
  margin-left: 10px;
}

.date + .editStatus {
  margin-left: 10px;
}

.date,
.editStatus span,
.reply span {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  /* identical to box height */

  color: #aaaaaa;
}

.date {
  position: relative;
  display: flex;

  &::before {
    position: relative;
    margin-right: 12px;
    content: '';
    height: 2px;
    width: 2px;
    display: block;
    border-radius: 50%;
    background-color: #aaaaaa;
    transform: translateY(10px);
  }
}

.reply {
  display: flex;
  gap: 5px;
}

.editStatus {
  gap: 3px;
  display: flex;
}

.mid {
  display: flex;
  align-items: flex-start;
  gap: 36px;

  & + .actions {
    margin-top: 10px;
  }
}

.contentCtn {
  //display: flex;
  // flex-direction: column-reverse;
  flex: 1 1 auto;
}

.content {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  overflow: hidden;

  color: #ffffff;
}

.showFull {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  text-align: start;
  width: fit-content;

  color: #ffa0bd;

  position: relative;

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 00;

    ~ span:nth-child(2) {
      display: none;
    }
    ~ span:nth-child(3) {
      display: block;
    }

    &:not(:checked) ~ {
      span:nth-child(2) {
        display: block;
      }
      span:nth-child(3) {
        display: none;
      }
    }
  }
}

.rating {
  display: flex;
  padding-right: 47px;
  gap: 60px;

  .ratingLike input:checked ~ * {
    --fill: #b8ff5e;
  }

  .ratingDislike input:checked ~ * {
    --fill: #ff4545;
  }

  .ratingDislike {
  }

  & > * {
    display: flex;
    align-items: flex-start;
    gap: 5px;
    position: relative;
    cursor: pointer;
    user-select: none;

    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      opacity: 0;
    }

    span {
      display: block;
      padding-left: 5px;
      position: absolute;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 29px;
      /* identical to box height */
      left: calc(100%);

      color: var(--fill, #5a5a5a);
    }
  }
}

.actions {
  gap: 30px;
  display: flex;
}

.actionAnswer,
.actionCancel {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  /* identical to box height */
}

.actionAnswer {
  color: #ffa0bd;
}
.actionCancel {
  color: #ff5151;
}

.actionDots {
  position: relative;
  width: fit-content;
}

.replyInput {
  margin-right: 180px;
}

.actionDotsInput {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;

  &:not(:checked) {
    & ~ .actionDotsMenu {
      display: none;
    }
  }
}

.actionDotsMenu {
  position: absolute;
  top: calc(100% + 3px);
  left: 0;
  z-index: 1;

  background: rgba(28, 27, 32, 0.7);
  border: 2px solid rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(50px);

  border-radius: 5px 15px 15px 15px;

  width: fit-content;
  padding: 10px;

  & > * {
    white-space: nowrap;
    width: 100%;
    color: var(--fill, #c4c4c4);
    display: flex;
    gap: 5px;
    align-items: center;

    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
    padding: 0px 10px;

    & + * {
      margin-top: 5px;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.1);
      border-radius: 10px;
    }
  }
}

.actionEdit,
.actionWarning {
  --fill: #c4c4c4;
}

.actionDelete {
  --fill: #ff4545;
}

.actionWarn {
  --fill: #ffca41;
}
.actionBan {
  --fill: #840000;
}
