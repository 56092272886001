.ctn {
  border-radius: 10px;
  position: relative;
  overflow: hidden;

  display: flex;
  padding: 2px 10px 2px 0px;
  align-items: center;
  & > * {
    position: relative;
  }
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  opacity: 0.3;
}

.bgOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(90deg, transparent 0%, #1b1a20 100%);
}

.num {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
  width: 60px;
}

.nameSecondary {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  /* identical to box height */

  color: #c4c4c4;

  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.namePrimary {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  /* identical to box height */

  color: #ffffff;

  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.nameHr {
  margin: 1px 0;
  background-color: rgba(255, 255, 255, 0.5);
}

.tags {
  margin-left: auto;
  display: flex;
  gap: 10px;
}

.tag {
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
  padding: 3px 10px;
  gap: 10px;
}
