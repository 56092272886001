.avatar {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  padding: 2px;

  background-color: rgba(255, 255, 255, 0.4);

  // background-image: linear-gradient(
  //   90deg,
  //   #ff5592 0%,
  //   #a661ff 49.98%,
  //   #92fff8 100%
  // );
}

.avatarCtn {
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  width: 100%;
  height: 100%;

  padding: 20%;

  svg {
    width: 100%;
    height: 100%;
  }

  background: #313131;
}

.avatarImage {
  background-color: white;
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  display: block;
  inset: 0;
}
