.ctn {
  padding-top: 81px - 50px;

  width: 1280px;
  margin: 0 auto;
}

.searchField {
  //height: 40px;
  position: relative;

  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1)
    ),
    rgba(28, 27, 32, 0.9);
  border: 2px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(25px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 15px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 18px;
  padding-right: 13px;
  box-sizing: border-box;

  display: flex;

  input {
    width: 100%;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 32px;
    /* identical to box height */

    color: #ffffff;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    inset: -2px;
    pointer-events: none;

    border-bottom: 2px solid #ffffff;
    border-radius: 15px;
  }
}

.searchFieldButtonX {
  &,
  svg {
    display: block;
  }
}

.filterChip {
  display: flex;
  box-sizing: border-box;
  padding: 0px 8px;
  padding-right: 3px;
  align-items: center;
  justify-content: center;

  background: rgba(28, 27, 32, 0.9);
  backdrop-filter: blur(25px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 12px;

  position: relative;
  user-select: none;

  border: 2px solid rgba(255, 255, 255, 0.4);

  &:has(.filterChipNumber) {
    border-color: #ffffff;
  }
}

.filterChipText {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;

  color: var(--fill, #c4c4c4);
  margin-left: 5px;
}

.filterChipNumber {
  position: absolute;
  top: -12px;
  right: -20px;

  width: 46px;
  height: 20px;

  background: #ffffff;
  border-radius: 10px;
  text-align: center;

  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  /* identical to box height, or 100% */

  color: #484848;
}

.filterChipExpandIcon {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 0px 2px;
  margin-left: auto;

  svg {
    transition: transform 0.2s;
    display: block;
  }
}

.filters {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-gap: 29px;
  margin-top: 20px;
}

.filterSelectLabel {
  display: block;
}
.filterHiddenCheckbox {
  position: absolute;
  opacity: 0;
}

.filterHiddenCheckbox:checked + {
  .filterChip {
    --fill: #ffffff;

    .filterChipExpandIcon svg {
      transform: rotate(90deg);
    }

    border-color: #ffffff;

    border-bottom-color: transparent;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.filterSelectMenu {
  background: rgba(28, 27, 32, 0.9);
  border: 2px solid #ffffff;
  backdrop-filter: blur(15px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 12px;

  width: 160px;

  border-top: 0px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding-top: 3px;
}

.filterSelectMenuDrop {
  padding: 8px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
}

.filterSelectMenuVariant {
  position: relative;
  display: block;
  user-select: none;
  cursor: pointer;

  & + .filterSelectMenuVariant {
    margin-top: 5px;
  }
}

.filterDateField {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  user-select: none;
  // cursor: pointer;
  justify-content: flex-end;
  gap: 5px;

  div {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    width: 23px;
    flex: none;

    color: #898989;
  }

  & + .filterDateField {
    margin-top: 5px;
  }

  input {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;

    color: #ffffff;
    padding: 3px 10px 4px;
    width: 100%;

    &:not(:placeholder-shown),
    &:focus {
      background: rgba(255, 255, 255, 0.2);

      & + div {
        color: #ffffff;
      }
    }
  }
  // &:placeholder-shown {
  //   background: rgba(255, 255, 255, 0.05);
  // }
}

.filterSelectMenuVariantInput {
  position: absolute;
  opacity: 0;
}

.filterSelectMenuVariantValue {
  color: #ffffff;

  background: rgba(255, 255, 255, 0.2);
}

.filterSelectMenuVariantInput:checked + .filterSelectMenuVariantValue {
  background: #ffffff;
  color: #484848;
}

.filterSelectMenuVariantValue,
.filterSelectMenuReset {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  padding: 0px 10px;
  border-radius: 10px;
  width: 100%;
}

.filterSelectMenuReset {
  color: #ff4545;
  background: rgba(255, 69, 69, 0.2);

  margin-top: 10px;
}

.resultsCounter {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 30px;

  margin-top: 56px;

  color: #878787;
}
.actionsCtn {
  margin-top: 40px;
}

.results {
  margin-top: 19px;
}
