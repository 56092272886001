.page {
  padding-bottom: 140px;
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 58px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
}

.lastupdts {
  margin-top: 200px;
}

.popularReleases {
  margin-top: 200px;
}

.popularGenres {
  margin-top: 200px;
  margin-bottom: 40px;
}

.importantNews {
  margin-top: 200px;
  margin-bottom: 40px;
}
